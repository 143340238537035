/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion'

import SEO from 'src/components/organisms/SEO'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
} from 'src/animations'
import { sm } from 'src/gatsby-plugin-theme-ui/utils'
import Image404Desktop from 'src/images/404-desktop.png'
import Image404Mobile from 'src/images/404-mobile.png'

const NotFoundPage = () => {
  return (
    <div>
      <SEO
        is404={true}
        title="Not Found"
        image=""
        description="Page not found"
        path=""
        // alternate_languages=""
        lang=""
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <img
          sx={{ width: '100%', display: sm('none', 'block') }}
          src={Image404Desktop}
          alt="Page not found"
        />
        <img
          sx={{
            width: '100%',
            display: sm('block', 'none'),
            mt: 'xxl',
            mb: 'xxl2',
          }}
          src={Image404Mobile}
          alt="Page not found"
        />
      </motion.div>
    </div>
  )
}

NotFoundPage.defaultProps = {
  pageContext: { __layout: { navTextColor: 'text', hideBottomBlock: true } },
}

export default NotFoundPage

/*
// If an unpublished document is previewed, these templates will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    project: ProjectTemplate,
    prismicProject: ProjectTemplate,
    homepage: HomePage
    // TODO delete after testing preview is working
    // prismicHomepage: HomePage,
    // Project: ProjectTemplate,
    // PrismicProject: ProjectTemplate,
    // Homepage: HomePage,
    // PrismicHomepage: HomePage
  }
})
 */
